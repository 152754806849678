import 'jquery';
import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/carousel';
import './sass/main.scss';

function SVGsupport () {
    return !!document.createElementNS && !!document.createElementNS('http://www.w3.org/2000/svg', "svg").createSVGRect;
}

if (SVGsupport() === false) {
    $('#logo').attr('src', 'assets/img/png.svg');
}

function calcCarouselHeight () {

    var cMaxHeight = 0;

    $("#beteiligungen-carousel .carousel-item").each(function (i, el) {
        if ($(el).height() > cMaxHeight) {
            cMaxHeight = $(el).height();
        }
    });

    cMaxHeight += 50;

    $("#beteiligungen-carousel").attr("style", "min-height:" + parseInt(cMaxHeight) + "px")
}

if ($("#beteiligungen-carousel").length) {
    window.setTimeout(function () {
        calcCarouselHeight();
    }, 0)

    $(window).resize(function () {
        calcCarouselHeight();
    })
}

jQuery.fn.carousel.Constructor.TRANSITION_DURATION = 1000;
